<template>
    <div>
        <router-view></router-view>
    </div>
</template>
  
<script>
export default {
    name: 'App',
    mounted() {
        this.$router.afterEach((to) => {
            const pageTitle = to.meta.title || '默认标题';
            document.title = pageTitle;
        });
    }
};
</script>