<template>
  <div>
    <img :src="require('@/assets/404_images/404.png')" alt="404" />
    <img :src="require('@/assets/404_images/404_cloud.png')" alt="404" />
    <img :src="require('@/assets/404_images/404_cloud.png')" alt="404" />
    <img :src="require('@/assets/404_images/404_cloud.png')" alt="404" />
    <router-link to="/indexPage">
      回到充值界面
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NoFoundPage',
}
</script>
