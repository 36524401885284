<template>
  <div class="payment-success">
    <h1 class="title">支付失败</h1>
    <img class="image" :src="require('@/assets/payFailure.png')">
    <p class="message">请联系工具相关管理员处理！谢谢！</p>
  </div>
</template>

<script>
export default {
  name: 'FailurePage',
  components: {

  },
}
</script>

<style scoped>
.payment-success {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.image {
  max-width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.message {
  font-size: 18px;
  margin-bottom: 30px;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
}

/* 响应式布局 */
@media (max-width: 768px) {
  .payment-success {
    padding: 20px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .image {
    margin-bottom: 20px;
    width: 80px; /* 在小屏幕设备上设置较小的宽度 */
    height: 80px; /* 在小屏幕设备上设置较小的高度 */
  }

  .message {
    font-size: 16px;
    margin-bottom: 25px;
  }

}
</style>