import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router/index'; // 导入路由实例

Vue.config.productionTip = false

Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
  const pageTitle = to.meta.title || '默认标题';
  document.title = pageTitle;
  next();
});

new Vue({
  router, // 挂载路由实例
  render: h => h(App),
}).$mount('#app')
