/**
 * 枚举类
 * @author rainDreamer
 */
export class Enum {
  /**
     * 添加枚举字段
     * field: 枚举字段
     * label: 界面显示
     * value: 枚举值
     */
  add(field, label, value) {
    this[field] = { label, value }
    return this
  }

  /**
    * 根据枚举value获取其label
  */
  getLabelByValue(value) {
    // 字段不存在返回‘’
    if (value === undefined || value === null) {
      return '未设置'
    }
    for (const i in this) {
      const e = this[i]
      if (e && e.value === value) {
        return e.label
      }
    }
    return ''
  }

  /**
    * 根据枚举Name获取其label
  */
   getNameByValue(value) {
    // 字段不存在返回‘’
    console.log("sdfdafdafang")
    for (const i in this) {
      const e = this[i]
      if (e && e.value === value) {
        return i
      }
    }
    return ''
  }

  /**
    * 根据枚id获取其value
  */
  getValueById(id) {
    // 字段不存在返回‘’
    return this[id] ? this[id].value : undefined
  }

  getData(isUnndefined = true, exclude = []) {
    var data = []
    exclude.push('未设置')
    exclude.push('未知')
    for (const i in this) {
      const e = this[i]
      if (e && (e.value !== undefined || isUnndefined)) {
        var excludes = false
        for (let index = 0; index < exclude.length; index++) {
          const element = exclude[index]
          if (element === e.label) {
            excludes = true
            break
          }
        }
        if (!excludes) data.push(e)
      }
    }
    return data
  }
}
