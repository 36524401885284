<template>
    <div>
        <!-- <h1>支付界面</h1>
        <p>这是一个支付界面</p>
        <p>URL地址：</p>
        <p>{{ URL }}</p>
        <p>code:{{ listQuery.code }}</p>
        <p>appid:{{ appid }}</p>
        <p>cardId:{{ listQuery.cardNumber }}</p>
        <p>platform:{{ listQuery.platform }}</p>
        <p>scoreNum:{{ listQuery.moneyTotal }}</p>
        <p>payType:{{ URLParams.payType }}</p>
        <p>====================================</p>
        <p>服务端返回参数:{{ wxPrepayList }}</p>
        <p>====================================</p>
        <p>调用支付接口参数:{{ testParams }}</p>
        <p>====================================</p>
        <p>签名加密前:{{ testParams }}</p> -->
        <p>提示:{{ msg }}</p>
    </div>
</template>

<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import CryptoJS from 'crypto-js';
import { createOrder, getOrderStatus } from "@/api/common"
import { createOrder_hk, getOrderStatus_hk } from "@/api/common_hk"

export default {
    name: 'PayPage',
    data() {
        return {
            timerId:null,
            wxPrepayList: [],
            listQuery: {
                platform: undefined,
                cardNumber: undefined,
                moneyTotal: 0,
                code: '',
            },

            URL: '',
            URLParams: null, // 存储URL参数的变量
            appid: '',
            orderNum:'',//订单号
            // testParams: undefined,
            msg :'请稍后···',
            index:0,

        };
    },
    mounted() {

    },
    created() {
        this.getUrlData()
    },
    methods: {
        getUrlData() {
            this.URL = window.location.href
            // this.URL = 'https://test-wxpay.atomgameteam.com/?code=021UEA0w3lZGn13OTo2w3yuacq0UEA07&state=STATE#/payPage/?appid=wx9b72d668ec1b7e45&cardId=cjr99l032lbului7ojn0087824&platform=6&scoreNum=10&payType=0'
            const url1 = new URL(this.URL)
            const params = new URLSearchParams(url1.search);
            this.listQuery.code = params.get("code");
            // 提取 appid 参数
            const url2 = new URL(this.URL)
            const hashParams = url2.hash.substring(1).split("&");
            const str = hashParams[0];
            const regex = /\/payPage\/\?appid=(\w+)/;
            const match = str.match(regex);
            if (match && match[1]) {
                const appid = match[1];
                this.appid = appid;
            } else {
                this.appid = '';
            }
            // 遍历参数并将其存储在变量中（可根据需要自行处理）
            let paramsObject = {}
            for (let param of hashParams) {
                const [key, value] = param.split("=");
                paramsObject[key] = value;
            }
            this.URLParams = paramsObject

            this.listQuery.platform = parseInt(this.URLParams.platform)
            this.listQuery.moneyTotal = parseInt(this.URLParams.scoreNum)
            this.listQuery.cardNumber = this.URLParams.cardId

            this.createOrderList()

        },


        //创建订单
        createOrderList() {
            var data = this.listQuery;
            if (this.listQuery.platform == 6) {
                createOrder(data).then(res => {
                if (res.code === 0) {
                    this.wxPrepayList = res.data.wxPrepay

                    var params = {}
                    params.appId = this.wxPrepayList.appId
                    params.timeStamp = this.wxPrepayList.timeStamp
                    params.nonceStr = this.wxPrepayList.nonceStr
                    params.package = this.wxPrepayList.package
                    params.signType = this.wxPrepayList.signType
                    params.paySign = this.wxPrepayList.paySign
                    // this.testParams = params
                    this.orderNum = this.wxPrepayList.outTradeNo

                    this.timerId = setInterval(() => {
                                    this.checkOrderStatus();
                                }, 2000);

                    function onBridgeReady() {
                        WeixinJSBridge.invoke('getBrandWCPayRequest',params ,function(res) {
                            if (res.err_msg == "get_brand_wcpay_request:ok") {
                            // 使用以上方式判断前端返回,微信团队郑重提示：
                            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                            }
                        });
                    }
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                        }
                    } else {
                         onBridgeReady();
                    }
                }
            })
            }else{
                createOrder_hk(data).then(res => {
                if (res.code === 0) {
                    this.wxPrepayList = res.data.wxPrepay

                    var params = {}
                    params.appId = this.wxPrepayList.appId
                    params.timeStamp = this.wxPrepayList.timeStamp
                    params.nonceStr = this.wxPrepayList.nonceStr
                    params.package = this.wxPrepayList.package
                    params.signType = this.wxPrepayList.signType
                    params.paySign = this.wxPrepayList.paySign
                    // this.testParams = params
                    this.orderNum = this.wxPrepayList.outTradeNo

                    this.timerId = setInterval(() => {
                                    this.checkOrderStatus();
                                }, 2000);

                    function onBridgeReady() {
                        WeixinJSBridge.invoke('getBrandWCPayRequest',params ,function(res) {
                            if (res.err_msg == "get_brand_wcpay_request:ok") {
                            // 使用以上方式判断前端返回,微信团队郑重提示：
                            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                            }
                        });
                    }
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                        }
                    } else {
                         onBridgeReady();
                    }
                }
            })
            }

        },
        //查询订单支付状态
        checkOrderStatus() {
            var data = {};
            data.outTradeNo = this.orderNum
            this.index = this.index +2;
            this.msg = `正在检查是否支付，请稍后！已等待${this.index}秒···(如果超过1分钟，请关闭此界面，联系管理员！)`
            if (this.listQuery.platform == 6) {
                getOrderStatus(data).then(res => {
                if (res.code === 0) {
                    if (res.data.tradeStatus === 'SUCCESS') {
                    // 跳转到支付成功页面
                    clearInterval(this.timerId);
                        this.$router.push('/successPage');
                    }
                }
            }) 
            }else{
                getOrderStatus_hk(data).then(res => {
                if (res.code === 0) {
                    if (res.data.tradeStatus === 'SUCCESS') {
                    // 跳转到支付成功页面
                    clearInterval(this.timerId);
                        this.$router.push('/successPage');
                    }
                }
            })
            }

        }
    }

}
</script>
<style scoped></style>