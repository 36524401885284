import Vue from 'vue';
import VueRouter from 'vue-router';

// 导入路由组件
import IndexPage from '@/views/index.vue';
import PayPage from '@/views/payPage.vue';
import SuccessPage from '@/views/successPage.vue';
import FailurePage from '@/views/failurePage.vue';
import NoFoundPage from '@/views/error-page/404.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/indexPage',
  },
  {
    path: '/indexPage',
    component: IndexPage,
    meta: {
      title: '充值页面'
    }
  },
  {
    path: '/404',
    component: NoFoundPage,
    meta: {
      title: '404'
    }
  },
  {
    path: '/payPage',
    component: PayPage,
    meta: {
      title: '支付页面'
    }
  },
  {
    path: '/successPage',
    component: SuccessPage,
    meta: {
      title: '支付成功'
    }
  },
  {
    path: '/failurePage',
    component: FailurePage,
    meta: {
      title: '支付失败'
    }
  }
];

const router = new VueRouter({
  mode: 'hash', // 可选项：'hash' | 'history'，指定路由模式
  routes
});


export default router;